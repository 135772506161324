.App {
  /* text-align: center; */
  margin:10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.topspace {padding-top:15px;}

.fixture-body.hidden {display:none}

.filter {border:1px solid #ccc;padding:10px;}
.filter-title {margin-right:10px;}
.filter-link {cursor: pointer;color:#3f71db;margin-right:10px;}

h1 {font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;font-weight: normal;font-size: larger;}

div.playername {width:200px;display:inline-block;}
div.playerrating {width:30px;display:inline-block;}
div.playerposition {width:30px;display:inline-block;}
.players {margin-left:20px;margin-bottom:10px;font-size:smaller;}
.players h2 {font-weight: bold;}
.players h3 {font-weight: bold;}

a.external-reportlink {text-decoration: underline;margin-left:5px;}
a {text-decoration: none;}

.dontcopy {color:#ccc !important;}

nav.nav-main {margin-bottom:10px;}
nav.nav-leagues {margin-bottom:10px;font-size:smaller;}
nav.nav-main .navbar-item.active {border-bottom:3px solid #3f71db}
nav.nav-leagues .navbar-item.active {border-bottom:3px solid #3f71db}

.league-holder { margin-bottom: 20px;}
.league-title {font-weight: bold;}

.panel.smaller {font-size:13px;}
/* // .panel.smaller .label {margin-right:10px;} 
// .panel.smaller button {} */

.handicap.active {font-weight:bold;color:darkgoldenrod !important}

.header {margin-bottom:10px;}

.red-card {background-color: red;color:white;padding:2px;position: relative;left:-2px;}

/* table.market-overview {
  // border-collapse: collapse;
  // td,th {padding-left:5px;padding-right:5px;border:1px solid #ccc;margin:0;min-width:50px;}
}
table.market-history {
  // border-collapse: collapse;
  // td,th {padding-left:5px;padding-right:5px;border:1px solid #ccc;margin:0;}
} */

.handicap {font-weight:bold;cursor:pointer;color:#5555ff !important;}
.handicap-notoggle {font-weight: bold;}
div.price {}
div.change-direction-1 {color:green;font-family:'Segoe UI Semibold', Tahoma, Geneva, Verdana, sans-serif}
div.change-direction-0 {color:#333;font-family:'Segoe UI Semibold', Tahoma, Geneva, Verdana, sans-serif}
div.change-direction--1 {color:red;font-family:'Segoe UI Semibold', Tahoma, Geneva, Verdana, sans-serif}
/* // @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// } */

.back-red {background-color: #c4403f;text-align: center;color:#fff;}
.back-green {background-color: #7ba55d;text-align: center;color:#fff;}
.back-grey {background-color: #76788d;text-align: center;color:#fff;}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
 
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
